<template>
    <div>
        <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
                <span class="ahcRed subheading">
                    <b>Overzicht</b>
                </span>
            </v-system-bar>
            <v-alert outlined color="#ebb63b">
                <v-flex xs12 class="ma-2">
                    <v-row>
                        <v-col class="d-flex justify-start">
                            <v-card flat><v-chip color="#ebb63b" text-color="#cc212f">Aantal vrijwilligers in database:
                                    {{ volunteers.length }}</v-chip></v-card>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-icon color="#cc212f" @click="downloadTotalOverview">mdi-download</v-icon>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-start">
                            <v-card flat><v-chip color="#ebb63b" text-color="#cc212f">Actieve vrijwilligers dit seizoen:
                                    {{ activeVolunteers.length }}</v-chip></v-card>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-icon color="#cc212f" @click="downloadActiveOverview">mdi-download</v-icon>
                    </v-row>
                </v-flex>
            </v-alert>
        </v-card>
    </div>
</template>
<script>

export default {
    name: 'Overview',
    computed: {
        volunteers() {
                return this.$store.state.volunteerStore.volunteers;
            },
            selectedEdition() {
                return this.$store.state.volunteerStore.selectedEdition;
            },
            activeVolunteers() {
                let vol = [];
                this.volunteers?.forEach(volunteer => {
                    let volunteerCounted = false;
                    let keys = Object.keys(volunteer.activities);
                    if (keys.length > 0) {
                        keys.forEach(key => {
                            if (volunteer.activities[key].includes(this.selectedEdition) && !volunteerCounted) {
                                volunteerCounted = true;
                                vol.push(volunteer);
                            }
                        })
                    }
                });


                return vol;
            }
    },
    methods: {
        downloadTotalOverview() {
            this.$store.dispatch('volunteerStore/downloadOverview', {volunteers: this.volunteers, activity: "Alle vrijwilligers in de database"});
        },
        downloadActiveOverview() {
            this.$store.dispatch('volunteerStore/downloadOverview', {volunteers: this.activeVolunteers, activity: `Alle vrijwilligers in het seizoen ${this.selectedEdition}`});
        }
    }

}


</script>