<template>
  <nav>
    <v-app-bar app flat>
      <v-app-bar-nav-icon
        class="grey--text"
        @click="drawer = !drawer"
        v-if="loggedIn"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="grey--text">
        <span class="font-weight-black">AHC</span>
        <span class="font-weight-light">terdeur</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <h4 class="grey--text" v-if="currentUser">
        <small>Gebruiker:</small> {{ currentUser.name }}
      </h4>
      <v-btn text color="grey" @click="logout">
        <span>Uitloggen</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" color="#ebb63b">
      <v-img
        src="@/assets/logo_ahc.png"
        contain
        height="120px"
        class="ma-2"
      ></v-img>
      <v-list v-if="currentUser">
        <v-list-item router :to="'/'">
          <v-list-item-action>
            <v-icon class="white--text">mdi-human-male-height</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text"
              >Persoonlijke pagina</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="generalPermissionCheck('clubAdmin')"
          router
          :to="'/club'"
        >
          <v-list-item-action>
            <v-icon class="white--text">mdi-human-queue</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text"
              >Club van 111</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="generalPermissionCheck('vol')"
          router
          :to="'/boerenploeg'"
        >
          <v-list-item-action>
            <v-icon class="white--text">mdi-human-queue</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text"
              >Boerenploeg</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-expansion-panels flat>
        <v-expansion-panel v-if="generalPermissionCheck('sec')">
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-desktop-mac-dashboard</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Secretatiaat</span>
              </v-flex>
            </v-layout>
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list v-if="currentUser">
              <v-list-item
                v-for="link in userSecretariaatLinks"
                :key="link.text"
                router
                :to="link.route"
              >
                <v-list-item-action>
                  <v-icon class="white--text">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>{{ link.text }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :href="'https://drive.google.com/drive/folders/0B2YvujxBPcjWLU1vcW45cEZfYXc?resourcekey=0--sMFqrQ7whgadNEIcwAGww&usp=sharing'"
                target="_blank"
              >
                <v-list-item-action>
                  <v-icon class="white--text">mdi-camera</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>Foto bibliotheek</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="generalPermissionCheck('stockAdmin')"
        >
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-basket-outline</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Voorraad beheer</span>
              </v-flex>
            </v-layout>

            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list>
              <v-list-item
                v-for="link in webshopLinks"
                :key="link.text"
                router
                :to="link.route"
              >
                <v-list-item-action>
                  <v-icon class="white--text">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>{{ link.text }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="generalPermissionCheck('krant')">
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-monitor</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Krant</span>
              </v-flex>
            </v-layout>

            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list>
              <v-list-item
                v-if="
                  generalPermissionCheck('krantRed')
                "
                router
                to="/krant_administratie"
              >
                <v-list-item-action>
                  <v-icon class="white--text"
                    >mdi-newspaper-variant-outline</v-icon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>Administratie</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="generalPermissionCheck('krantAdv')" router to="/mijn_adverteerders">
                <v-list-item-action>
                  <v-icon class="white--text">mdi-clipboard-list</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>Mijn advertenties</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="
            generalPermissionCheck('web')
          "
        >
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-monitor</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Website beheer</span>
              </v-flex>
            </v-layout>

            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list>
              <v-list-item
                v-for="link in userWebsiteLinks"
                :key="link.text"
                router
                :to="link.route"
              >
                <v-list-item-action>
                  <v-icon class="white--text">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>{{ link.text }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
       
        <v-expansion-panel>
          <v-expansion-panel-header color="#ebb63b">
            <v-layout row>
              <v-flex xs3>
                <v-icon class="white--text">mdi-lan</v-icon>
              </v-flex>
              <v-flex xs7>
                <span color="#ebb63b" class="white--text">Commissies</span>
              </v-flex>
            </v-layout>
            <template v-slot:actions>
              <v-icon color="white">$expand</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#ebb63b" class="white--text">
            <v-list >
              <v-list-item
                v-for="wg in userComPermissions"
                :key="wg.id"
                router
                :to="`/commissies/${wg.id}`"
              >
                <v-list-item-action>
                  <v-icon class="white--text">mdi-account-hard-hat</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    <small>{{ wg.index }} - {{ wg.name }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import firebase from "firebase";
export default {
  name: "Navbar",
  data() {
    return {
      drawer: false,
      websiteLinks: [
        {
          icon: "mdi-newspaper-variant-outline",
          text: "Nieuwsberichten",
          route: "/nieuwsberichten",
          id: "webNews",
        },
        {
          icon: "mdi-music",
          text: "optocht",
          route: "/optocht",
          id: "webOptocht",
        },
        { icon: "mdi-view-dashboard", text: "Home", route: "/homepage", id: "webHome", },
        {
          icon: "mdi-glass-mug-variant",
          text: "Ulster Bierproeffestival",
          route: "/ubpf",
          id: "webUBPF",
        },
        {
          icon: "mdi-music",
          text: "Liedjesfestival",
          route: "/liedjesfestival",
          id: "webLiedjes",
        },
        {
          icon: "mdi-account-group",
          text: "Jeugdraad",
          route: "/jeugdraad",
          id: "webJeugdraad",
        },

        {
          icon: "mdi-newspaper-variant",
          text: "Carnavalskrant",
          route: "/carnavalskrant",
          id: "webKrant",
        },
        {
          icon: "mdi-balloon",
          text: "Kindercarnaval",
          route: "/kindercarnaval",
          id: "webkinder",
        },
        {
          icon: "mdi-history",
          text: "Over ons",
          route: "/overons",
          id: "webHistory",
        },
        {
          icon: "mdi-comment-processing",
          text: "Contact",
          route: "/contact",
          id: "webContact",
        },
      ],
      webshopLinks: [
        {
          icon: "mdi-cupcake",
          text: "Overzicht Voorraad",
          route: "/voorraad/producten",
        },
        {
          icon: "mdi-tune-vertical",
          text: "instellingen",
          route: "/voorraad/instellingen",
        },
      ],
      secretariaatLinks: [
        {
          icon: "mdi-note-multiple",
          text: "Notulen / Agenda's",
          route: "/notulenagendas",
          id: "secNotAge"
        },
        {
          icon: "mdi-calendar-month",
          text: "Jaarprogramma",
          route: "/jaarprogramma",
          id: "secjaarProg",
        },
        {
          icon: "mdi-clipboard-list",
          text: "Commissielijst",
          route: "/commissielijst",
          id: "secComlist",
        },
        {
          icon: "mdi-account-multiple",
          text: "Ledenoverzicht",
          route: "/ledenlijst",
          id: "secLedlist",
        },
        {
          icon: "mdi-currency-eur",
          text: "Financiën",
          route: "/financien",
          id: "secFin",
        },
        {
          icon: "mdi-account-multiple",
          text: "Gebruikers beheer",
          route: "/gebruikers",
          id: "secGebruikers",
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    loggedIn() {
      return this.$store.state.loggedIn;
    },
    userGenPermissions() {
       return this.currentUser?.permissions.general ?? [];
    },
    userComPermissions() {
      let userComIds = this.currentUser?.permissions.commissions ?? [];
      let com = [];
      userComIds.forEach(uCom => {
        this.commissions?.forEach(commission => {
          if (commission.id == uCom) {
            com.push(commission);
          }
      })
    });
      com.sort((a, b) => a.index - b.index);
      return com
    },
    userSecretariaatLinks() {
      let links = [];
      this.secretariaatLinks.forEach((link) => {
        if (this.userGenPermissions.includes(link.id)) {
          links.push(link);
        }
      });
      return links;
    },
    commissions() {
      return this.$store.state.commissionStore.commissions ?? [];
    },
    userWebsiteLinks() {
      let links = [];
      this.websiteLinks.forEach((link) => {
        if (this.userGenPermissions.includes(link.id)) {
          links.push(link);
        }
      });
      return links;
    },
   
    
  },
  methods: {
    generalPermissionCheck(id) {
      return this.userGenPermissions.includes(id);
    },

    logout() {
      console.log("logging out");
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ name: "Login" });
        });
      this.$store.dispatch("clearCurrentUser");
    },
  },
  created() {
    this.$store.dispatch("loadIntros");
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("commissionStore/loadCommissions");
    setTimeout(() => {
      this.$store.dispatch("loadWorkgroups");
    }, 5000);
  },
};
</script>

<style>
.v-expansion-panel-header {
  width: 100%;
}
</style>