<template>
    <div>
        <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
                <span class="ahcRed subheading">
                    <b>Taken</b>
                </span>
                <v-spacer></v-spacer>
                <div class="d-flex" v-if=" !addTasksDialog">
                    <v-icon color="#cc212f" @click="addTasksDialog = true">mdi-file-plus-outline</v-icon>
                </div>
            </v-system-bar>
            <v-card-text v-if="noTasksAvailable
            ">
                <span>
                    Er zijn nog geen taken aangemaakt. Het is de taak van de
                    voorzitter om dit te verzorgen.
                </span>
            </v-card-text>
            <v-card-text v-else>
                <v-row class="justify-center">
                    <v-radio-group class="ma-3" v-model="view" row color="#ebb63b">
                        <v-radio label="Mijn taken" value="0"></v-radio>
                        <v-radio label="Openstaande taken" value="1"></v-radio>
                        <v-radio label="Alle taken" value="2"></v-radio>
                    </v-radio-group>
                </v-row>
                <v-row class="justify-center">
                    <TasksOverview class="ma-3" v-if="view == '0'" :taskFilter="'myOwn'" :tasks="tasks" :draaiboeken="draaiboeken" :commissionId="commissionId" :isPreces="isPreces"/>
        <TasksOverview class="ma-3" v-if="view == '1'" :taskFilter="'open'" :tasks="tasks" :draaiboeken="draaiboeken" :commissionId="commissionId" :isPreces="isPreces"/>
        <TasksOverview class="ma-3" v-if="view == '2'" :taskFilter="'all'" :tasks="tasks" :draaiboeken="draaiboeken" :commissionId="commissionId" :isPreces="isPreces"/>
      </v-row>
            </v-card-text>
        </v-card>
        <v-dialog transition="dialog-top-transition" max-width="800" v-model="addTasksDialog">
            <v-card v-if="!buzzy">
                <v-system-bar class="pa-4" color="#ebb63b">
                    <span class="ahcRed subheading">
                        <b>Taken toevoegen</b>
                    </span>
                </v-system-bar>
                <v-card-text>
                    <v-radio-group label="Type" v-model="taskType">
                        <v-radio label="Losse taak" value="single"></v-radio>
                        <v-radio label="Draaiboek activeren" value="fromDraaiboek"></v-radio>
                    </v-radio-group>
                    <span v-if="taskType === 'fromDraaiboek' && filteredDraaiboeken && filteredDraaiboeken.length > 0">Hiermee worden alle taken van een draaiboek toegevoegd aan
                        de lijst. Wanneer er al andere taken aan dit draaiboek gekoppeld waren worden deze
                        verwijderd.</span>
                    <v-select
                        v-if="taskType === 'fromDraaiboek' && filteredDraaiboeken && filteredDraaiboeken.length > 0"
                        v-model="selectedDraaiboek" :items="filteredDraaiboeken" item-text="name" item-value="id"
                        label="Draaiboek" required></v-select>
                    <span
                        v-if="taskType === 'fromDraaiboek' && (!filteredDraaiboeken || filteredDraaiboeken.length == 0)">Er
                        zijn nog geen draaiboeken aangemaakt voor deze commissie</span>
                    <v-text-field v-if="taskType === 'single'" v-model="newTask.name" label="Naam"
                        placeholder="Bakker bellen" required />
                    <v-textarea v-if="taskType === 'single'" v-model="newTask.description"
                        label="Omschrijving / notitie" placeholder="tel: 06-11223344" />
                    <v-select v-if="taskType === 'single'" v-model="newTask.responsable" :items="commissionMembers"
                        label="(Hoofd)verantwoordelijke" />
                    <v-radio-group v-if="taskType === 'single'" label="Type" v-model="newTask.timeType">
                        <v-radio label="Vooraf" value="upFront"></v-radio>
                        <v-radio label="Tijdens het evenement" value="duringEvent"></v-radio>
                        <v-radio label="Na het evenement" value="afterEvent"></v-radio>
                    </v-radio-group>
                    <v-select
                        v-if="taskType === 'single' && newTask.timeType && (newTask.timeType == 'upFront' || newTask.timeType == 'afterEvent')"
                        v-model="newTask.period" :items="months" item-text="label" item-value="label"
                        label="Wanneer moet het gebeuren"></v-select>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-1" text
                        @click="(addTasksDialog = false), (newTask = {})">Annuleren</v-btn>

                    <v-btn color="green darken-1" text
                        v-if="(taskType == 'fromDraaiboek' && selectedDraaiboek) || (taskType == 'single' && newTask.name)"
                        @click="saveTasks">Toevoegen</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-5" elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import TasksOverview from './TasksOverview.vue';

export default {
    name: "tasks",
    components: {
        TasksOverview,
    },
    props: {
        tasks: {
            type: Object,
            default: null,
        },
        isPreces: {
            type: Boolean,
            default: false,
        },
        draaiboeken: {
            type: Array,
            default: () => [],
        },
        commissionId: {
            type: String,
            required: true,
        },

    },
    data() {
        return {
            addTasksDialog: false,
            taskType: '',
            selectedDraaiboek: null,
            newTask: {},
            months: [
                { label: "Januari", value: 8 },
                { label: "Februari", value: 9 },
                { label: "Maart", value: 10 },
                { label: "April", value: 11 },
                { label: "Mei", value: 12 },
                { label: "Juni", value: 1 },
                { label: "Juli", value: 2 },
                { label: "Augustus", value: 3 },
                { label: "September", value: 4 },
                { label: "Oktober", value: 5 },
                { label: "November", value: 6 },
                { label: "December", value: 7 }
            ],
            expanded: [],
            buzzy: false,
            view: "0",



        };
    },
    computed: {
        filteredDraaiboeken() {
            return this.draaiboeken.filter((category) => {
                return !category.archived;
            });
        },
        commissionMembers() {
            let members = [];
            let comMembers = this.$store.getters["commissionStore/getCommissionUsers"](this.commissionId);
            let preces = this.$store.getters["commissionStore/getCommissionPreces"](this.commissionId);
            comMembers.forEach((member) => {
                members.push(member.name);

            });
            preces.forEach((preces) => {
                members.push(preces.name);
            });
            return members;
        },
        noTasksAvailable() {
            let noTasksAvailable = true;
            if (this.tasks) {
                let keys = Object.keys(this.tasks);
                if (keys.length > 0) {
                    keys.forEach((key) => {
                        if (this.tasks[key].length > 0) {
                            noTasksAvailable = false;
                        }
                    })
                }
            }
            return noTasksAvailable;
        },

    },
    methods: {
        saveTasks() {

            if (this.taskType === 'fromDraaiboek') {
                this.saveTasksFromDraaiboek();
            } else {
                this.buzzy = true;
                this.newTask.commissionId = this.commissionId;
                this.newTask.finished = false
                this.newTask.id = Date.now();
                this.$store.dispatch("commissionStore/addTask", {
                    task: this.newTask,
                    commissionId: this.commissionId,
                }).then((result) => {
                    if (result.success) {
                        this.addTasksDialog = false;
                        this.newTask = {};
                        this.buzzy = false;
                    } else {
                        alert("Er is iets fout gegaan bij het opslaan van de taak");
                        this.buzzy = false;
                    }
                });
            }
        },
        saveTasksFromDraaiboek() {
            this.buzzy = true;
            this.$store.dispatch("commissionStore/addTaskFromDraaiboek", {
                draaiboekId: this.selectedDraaiboek,
                commissionId: this.commissionId,
            }).then((result) => {
                if (result.success) {
                    this.addTasksDialog = false;
                    this.selectedDraaiboek = null;
                    this.buzzy = false;
                } else {
                    alert("Er is iets fout gegaan bij het opslaan van de taken");
                    this.buzzy = false;
                }
            });
        },
    },
}

</script>