<template>
    <v-container>
        <v-layout row wrap xs12 class="my-2 justify-space-between">
      <h1 class="d-flex">
        <span class="ahcRed">De Boerenploeg</span>
      </h1>
    </v-layout>
        <div v-if="volunteers">
            <v-tabs  class="my-5" fixed-tabs background-color="#ebb63b" dark>
      <v-tab @click="view = 'Overzicht'"> Overview </v-tab>
      <v-tab @click="view= 'Activity'">Activiteiten</v-tab>
      <v-tab @click="view = 'Vrijwilligers'"> Vrijwilligers </v-tab>
    </v-tabs>
    <div class="row justify-end mx-5" v-if="view !== 'Vrijwilligers'">
        <v-select
          :items="availableEditions"
            v-model="selectedEdition"
          label="Seizoen"
          dense
          outlined
        ></v-select>
    </div>
    <Overview v-if="view === 'Overzicht'"/>
    <VolunteerOverview v-if="view === 'Vrijwilligers'" :volunteers="volunteers"/>
    <ActivityOverview v-if="view === 'Activity'" :activities="activities" :volunteers="volunteers"/>
        </div>
        <div v-else>
            Bezig met laden...
        </div>
            
        
    </v-container>
    
</template>
<script>
    import VolunteerOverview from './VolunteerOverview.vue';
    import ActivityOverview from './ActivityOverview.vue';
    import Overview from './Overview.vue';
    export default {
        name: 'Volunteers',
        components: {
            VolunteerOverview,
            ActivityOverview,
            Overview
        },
        data(){
            return {
                view: 'Overzicht',
                selectedEdition: null
            }
        },

        watch: {
            selectedEdition() {
                if(this.selectedEdition !== null && this.selectedEdition !== undefined)
                this.$store.commit('volunteerStore/setSelectedEdition', this.selectedEdition);
            }
        },


        computed: {
            volunteers() {
                return this.$store.state.volunteerStore.volunteers;
            },
            availableEditions() {
                return this.$store.state.volunteerStore.availableEditions;
            },
            activities() {
                return this.$store.state.volunteerStore.activities;
            }
        },
        mounted(){
            this.$store.dispatch('volunteerStore/loadVolunteers');
            this.$store.dispatch('volunteerStore/getActivities');
            this.selectedEdition = this.$store.state.volunteerStore.selectedEdition;
        }




    }

</script>