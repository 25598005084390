import firebase from '../firebase/init'
export default {
    namespaced: true,
    state: {
        volunteers: null,
        loaded: false,
        selectedEdition: '-',
        currentEdition: null,
        activities: null,
        availableEditions: [
            '2023 - 2024',
            '2024 - 2025',
            '2025 - 2026',
            '2026 - 2027',
            '2027 - 2028',
            '2028 - 2029',
            '2029 - 2030',
            '2030 - 2031',
            '2031 - 2032',
            '2032 - 2033',
        ],
        randomFlag: false,
    },
    getters: {
    },

    mutations: {
        setVolunteers(state, { volunteers }) {
            state.volunteers = volunteers;
        },
        setActivities(state, { activities }) {
            state.activities = activities;
        },
        initEditions(state) {
            let currentEdition = '-';
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-based month
            if (currentMonth < 4) {
                currentEdition = `${currentYear - 1} - ${currentYear}`;
            } else {
                currentEdition = `${currentYear} - ${currentYear + 1}`;
            }
            state.currentEdition = currentEdition;
            state.selectedEdition = currentEdition;
        },
        setSelectedEdition(state,  selectedEdition ) {
            state.selectedEdition = selectedEdition;
            console.log("🚀 ~ setSelectedEdition ~ state.selectedEdition :", state.selectedEdition )
        },
        setRandomFlag(state) {
            state.randomFlag = !state.randomFlag;
        }
    },
    actions: {
        loadVolunteers({ commit }) {
            commit('initEditions')
            return new Promise((resolve, reject) => {
                try {
                    let loadVolunteers = firebase.functions().httpsCallable("getAllVolunteers");
                    loadVolunteers()
                        .then(result => {
                            if (result.data.success) {
                                commit('setVolunteers', { volunteers: result.data.volunteers });
                                resolve(result.data);
                            } else {
                                reject(result.data);
                            }
                        })
                        .catch(error => {
                            console.log("Error in loadVolunteers in store: " + error.message);
                            reject(error);
                        });
                } catch (error) {
                    console.log('Error in loadVolunteers: ' + error.message);
                    reject(error);
                }
            });
        },

        addVolunteer({ dispatch }, volunteer) {
            console.log('addVolunteer in store', volunteer);
            return new Promise((resolve, reject) => {
                try {
                    let addVolunteer = firebase.functions().httpsCallable("addNewVolunteer");
                    addVolunteer(volunteer)
                        .then(result => {
                            if (result.data.success) {
                                dispatch('loadVolunteers');
                                resolve(result.data);
                            } else {
                                reject(result.data);
                            }
                        })
                        .catch(error => {
                            console.log("Error in addVolunteer in store: " + error.message);
                            reject(error);
                        });
                } catch (error) {
                    console.log('Error in addVolunteer: ' + error.message);
                    reject(error);
                }
            });
        },

        updateVolunteer({ dispatch }, volunteer) {
            return new Promise((resolve, reject) => {
                try {
                    let updateVolunteer = firebase.functions().httpsCallable("updateVolunteer");
                    updateVolunteer(volunteer)
                        .then(result => {
                            if (result.data.success) {
                                dispatch('loadVolunteers');
                                resolve(result.data);
                            } else {
                                reject(result.data);
                            }
                        })
                        .catch(error => {
                            console.log("Error in updateVolunteer in store: " + error.message);
                            reject(error);
                        });
                } catch (error) {
                    console.log('Error in updateVolunteer: ' + error.message);
                    reject(error);
                }
            });
        },

        deleteVolunteer({ dispatch }, volunteer) {
            return new Promise((resolve, reject) => {
                try {
                    let deleteVolunteer = firebase.functions().httpsCallable("deleteVolunteer");
                    deleteVolunteer(volunteer)
                        .then(result => {
                            if (result.data.success) {
                                dispatch('loadVolunteers');
                                resolve(result.data);
                            } else {
                                reject(result.data);
                            }
                        })
                        .catch(error => {
                            console.log("Error in deleteVolunteer in store: " + error.message);
                            reject(error);
                        });
                } catch (error) {
                    console.log('Error in deleteVolunteer: ' + error.message);
                    reject(error);
                }
            });
        },

        getActivities({ commit }) {
            return new Promise((resolve, reject) => {
                try {
                    let getActivities = firebase.functions().httpsCallable("getActivities");
                    getActivities()
                        .then(result => {
                            if (result.data.success) {
                                commit('setActivities', { activities: result.data.activities });
                            } else {
                                reject(result.data);
                            }
                        })
                        .catch(error => {
                            console.log("Error in getActivities in store: " + error.message);
                            reject(error);
                        });
                } catch (error) {
                    console.log('Error in getActivities: ' + error.message);
                    reject(error);
                }
            });
        },

        addActivities({ dispatch }, activity) {
            return new Promise((resolve, reject) => {
                try {
                    let addActivity = firebase.functions().httpsCallable("addActivities");
                    addActivity(activity)
                        .then(result => {
                            if (result.data.success) {
                                dispatch('getActivities');
                                resolve(result.data);
                            } else {
                                reject(result.data);
                            }
                        })
                        .catch(error => {
                            console.log("Error in addActivity in store: " + error.message);
                            reject(error);
                        });
                } catch (error) {
                    console.log('Error in addActivity: ' + error.message);
                    reject(error);
                }
            });
        },
        addVolunteerToActivity({ dispatch, state }, payload) {
            return new Promise((resolve, reject) => {
                try {
                    let volunteer = state.volunteers.find(volunteer => volunteer.id === payload.volunteerId);
                    if(volunteer.activities[payload.activity]){
                        volunteer.activities[payload.activity].push(state.selectedEdition);
                    } else {
                        volunteer.activities[payload.activity] = [state.selectedEdition];
                    }
                    let addVolunteerToActivity = firebase.functions().httpsCallable("updateVolunteer");
                    addVolunteerToActivity({volunteer: volunteer})
                        .then(result => {
                            if (result.data.success) {
                                dispatch('loadVolunteers');
                                resolve(result.data);
                            } else {
                                reject(result.data);
                            }
                        })
                        .catch(error => {
                            console.log("Error in addVolunteerToActivity in store: " + error.message);
                            reject(error);
                        });
                } catch (error) {
                    console.log('Error in addVolunteerToActivity: ' + error.message);
                    reject(error);
                }
            });
        },
        removeVolunteerFromActivity({ dispatch, state }, payload) {
            return new Promise((resolve, reject) => {
                try {
                    let volunteer = state.volunteers.find(volunteer => volunteer.id === payload.volunteerId);
                    volunteer.activities[payload.activity] = volunteer.activities[payload.activity].filter(edition => edition !== state.selectedEdition);
                    let removeVolunteerFromActivity = firebase.functions().httpsCallable("updateVolunteer");
                    removeVolunteerFromActivity({volunteer: volunteer})
                        .then(result => {
                            if (result.data.success) {
                                dispatch('loadVolunteers');
                                resolve(result.data);
                            } else {
                                reject(result.data);
                            }
                        })
                        .catch(error => {
                            console.log("Error in removeVolunteerFromActivity in store: " + error.message);
                            reject(error);
                        });
                } catch (error) {
                    console.log('Error in removeVolunteerFromActivity: ' + error.message);
                    reject(error);
                }
            });
        },
        downloadOverview({commit}, payload) {
            console.log("🚀 ~ downloadOverview ~ payload:", payload)
            return new Promise((resolve, reject) => {
                try {
                    let volunteers = payload.volunteers;
                    let activityTitle = payload.activity;
        
                    // Create the content for the .txt file
                    let content = volunteers?.map(volunteer => `${volunteer.name}<${volunteer.email}>`).join('\n');
        
                    // Create a Blob from the content
                    let blob = new Blob([content], { type: 'text/plain' });
        
                    // Create a link to download the Blob as a .txt file
                    let link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = `${activityTitle}.txt`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    commit('setRandomFlag');
                    resolve();
                } catch (error) {
                    console.log('Error in downloadOverview: ' + error.message);
                    reject(error);
                }
            });
        }




    },
}