<template>
    <div>
        <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
                <span class="ahcRed subheading">
                    <b>Activiteiten</b>
                </span>
                <v-spacer></v-spacer>
                <!-- <div class="d-flex" v-if="!addActDialog">
                    <v-icon color="#cc212f" @click="addActDialog = true">mdi-file-plus-outline</v-icon>
                </div> -->
            </v-system-bar>
            <v-card-text v-if="noActAvailable
            ">
                <span>
                    Er zijn geen activiteiten om weer te geven.
                </span>
            </v-card-text>
            <v-card-text v-else>
                <v-data-table :headers="actHeaders" :items="activities" class="elevation-1" disable-pagination
                    hide-default-footer :single-expand="singleExpand" :expanded.sync="expanded" show-expand>
                    <template v-slot:expanded-item="{ headers }">
                        <td :colspan="headers.length">
                            <v-row class="ma-2">
                                <v-btn v-if="volunteersInAct.length > 0" dense @click="downloadOverview">Download email
                                    adressen</v-btn>
                            </v-row>
                            <v-row class="ma-2" v-for="volunteer in volunteersInAct" :key="volunteer.id">
                                <v-icon class="mx-2" color="#cc212f"
                                    @click="removeVolFromAct(volunteer.id)">mdi-minus-circle</v-icon>
                                <span>{{ volunteer.name }}</span>
                            </v-row>
                            <v-row class="d-flex justify-center ma-5"><v-icon color="#cc212f"
                                    @click="addVolDialog = true">mdi-plus</v-icon></v-row>
                        </td>
                    </template>
                    <template v-slot:[`item.count`]="{ item }">

                        <span>{{ getCount(item) }}</span>

                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="addActDialog" max-width="400">
            <v-card v-if="!buzzy">
                <v-card-title class="headline">Activiteit toevoegen</v-card-title>
                <v-card-text>
                    <v-text-field v-model="newAct.name" label="Naam" placeholder="Toren opbouwen" required />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="addActDialog = false, newAct = {}">Annuleren</v-btn>
                    <v-btn color="blue darken-1" text @click="saveAct()">Opslaan</v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-5" elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan activiteit</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="addVolDialog" max-width="400">
            <v-card v-if="!buzzy">
                <v-card-title class="headline">Vrijwilliger toevoegen</v-card-title>
                <v-card-text>
                    <v-autocomplete v-model="selectedVolunteer" item-text="name" item-value="id"
                        @input="saveVolunteerToActivity" :items="availableVolunteers" dense
                        label="Zoek vrijwilliger"></v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="addVolDialog = false">Annuleren</v-btn>
                </v-card-actions>
            </v-card>
            <v-card elevation="0" v-if="buzzy">
                <v-card-text>
                    <v-row>
                        <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                            indeterminate></v-progress-circular>
                    </v-row>
                    <v-row>
                        <h3>Opslaan vrijwilliger</h3>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

export default {
    name: 'activityOverview',



    data() {
        return {
            actHeaders: [
                { text: 'Naam', value: 'name' },
                { text: 'Aantal vrijwilligers', value: 'count', sortable: false }
            ],
            addActDialog: false,
            newAct: {
                name: ''
            },
            buzzy: false,
            singleExpand: true,
            expanded: [],
            volunteersInAct: [],
            addVolDialog: false,
            selectedVolunteer: null
        }
    },

    methods: {
        saveAct() {
            this.buzzy = true;
            this.$store.dispatch('volunteerStore/addActivities', { activities: this.newAct }).then(() => {
                this.buzzy = false;
                this.addActDialog = false;
                this.newAct = {};
            });
        },
        getVolunteersInAct(id) {
            let vol = [];
            this.volunteers?.forEach(volunteer => {
                if (volunteer.activities[id]?.includes(this.selectedEdition)) {
                    vol.push(volunteer);
                }
            });
            this.volunteersInAct = vol;
        },
        saveVolunteerToActivity() {
            this.buzzy = true;
            try {
                this.$store.dispatch('volunteerStore/addVolunteerToActivity', { volunteerId: this.selectedVolunteer, activity: this.expanded[0]?.id }).then(() => {
                    this.buzzy = false;
                    this.addVolDialog = false;
                    this.selectedVolunteer = null;
                    this.getVolunteersInAct(this.expanded[0]?.id);
                });
            } catch (e) {
                console.log(e);
            }
        },

        removeVolFromAct(volId) {
            this.buzzy = true;
            this.$store.dispatch('volunteerStore/removeVolunteerFromActivity', { volunteerId: volId, activity: this.expanded[0]?.id }).then(() => {
                this.buzzy = false;
                this.getVolunteersInAct(this.expanded[0]?.id);
            });
        },

        getCount(activity) {
            let count = 0;
            this.volunteers?.forEach(volunteer => {
                if (volunteer.activities[activity.id]?.includes(this.selectedEdition)) {
                    count++;
                }
            });
            return count;
        },
        downloadOverview() {
            this.$store.dispatch('volunteerStore/downloadOverview', { volunteers: this.volunteersInAct, activity: `${this.expanded[0]?.name} ~ ${this.selectedEdition}` });
        }

    },
    watch: {
        expanded() {
            this.volunteersInAct = [];
            this.getVolunteersInAct(this.expanded[0]?.id);
        },
        selectedEdition() {
            this.getVolunteersInAct(this.expanded[0]?.id);
        }
    },


    computed: {
        noActAvailable() {
            return !this.activities || this.activities.length === 0;
        },

        selectedEdition() {
            return this.$store.state.volunteerStore.selectedEdition;
        },
        activities() {
            return this.$store.state.volunteerStore.activities;
        },
        volunteers() {
            return this.$store.state.volunteerStore.volunteers;
        },
        availableVolunteers() {
            let vol = [];
            if (this.expanded.length > 0) {
                this.volunteers?.forEach(volunteer => {
                    if (!volunteer.activities[this.expanded[0]?.id]?.includes(this.selectedEdition)) {
                        vol.push(volunteer);
                    }
                });
            }
            return vol;
        },


    }
}
</script>