<template>
    <div>
        <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
                <span class="ahcRed subheading">
                    <b>Vrijwilligers</b>
                </span>
                <v-spacer></v-spacer>
                <div class="d-flex" v-if="!addVolDialog">
                    <v-icon color="#cc212f" @click="addVolDialog = true">mdi-file-plus-outline</v-icon>
                </div>
            </v-system-bar>
            <v-card-text v-if="noVolAvailable
            ">
                <span>
                    Er zijn geen vrijwilligers om weer te geven.
                </span>
            </v-card-text>
            <v-card-text v-else>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" class="ma-5" single-line
                        hide-details></v-text-field>
                    <v-data-table :headers="volHeaders" :items="volunteers" class="elevation-1" disable-pagination
                        hide-default-footer 
                        :search="search">
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editVol = item, editVolDialog = true">
                                        {{  'mdi-pencil' }}
                                    </v-icon>

                                </template>
                                <span>edit</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="volToDelete = item.id, delVolDialog = true">
                                        {{  'mdi-trash-can-outline' }}
                                    </v-icon>

                                </template>
                                <span>delete</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>

                   
</v-card-text>
</v-card>
<v-dialog v-model="addVolDialog" max-width="400">
                        <v-card v-if="!buzzy">
                            <v-card-title class="headline">Vrijwilliger toevoegen</v-card-title>
                            <v-card-text >
                                <v-text-field  v-model="newVol.name" label="Naam"
                                    placeholder="Pietje Bel" required />
                                    <v-text-field  v-model="newVol.email" label="Email"
                                    placeholder="pietje@bel.nl"  />
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text
                                    @click="addVolDialog = false, newVol = {}">Annuleren</v-btn>
                                <v-btn color="blue darken-1" text @click="saveVol()">Opslaan</v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card  elevation="0" v-if="buzzy">
                            <v-card-text>
                                <v-row>
                                    <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                                        indeterminate></v-progress-circular>
                                </v-row>
                                <v-row>
                                    <h3>Opslaan Vrijwilliger</h3>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="editVolDialog" max-width="400">
                        <v-card v-if="!buzzy">
                            <v-card-title class="headline">Vrijwilliger aanpassen</v-card-title>
                            <v-card-text >
                                <v-text-field  v-model="editVol.name" label="Naam"
                                    placeholder="Pietje Bel" required />
                                    <v-text-field  v-model="editVol.email" label="Email"
                                    placeholder="pietje@bel.nl"  />
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text
                                    @click="editVolDialog = false, editVol = {}">Annuleren</v-btn>
                                <v-btn color="blue darken-1" text @click="updateVol()">Opslaan</v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card  elevation="0" v-if="buzzy">
                            <v-card-text>
                                <v-row>
                                    <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                                        indeterminate></v-progress-circular>
                                </v-row>
                                <v-row>
                                    <h3>Vrijwilliger updaten</h3>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="delVolDialog" max-width="400">
                        <v-card v-if="!buzzy">
                            <v-card-title class="headline">Vrijwilliger verwijderen</v-card-title>
                            <v-card-text >
                                Weet je zeker dat je deze vrijwilliger wilt verwijderen? Dit kan niet ongedaan gemaakt worden. Vanuit privacy overwegingen zal de vrijwilliger ook verwijderd worden bij alle activiteiten waar deze aan gekoppeld is.
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text
                                    @click="delVolDialog = false, volToDelete = null">Annuleren</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteVol()">Verwijderen</v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card elevation="0" v-if="buzzy">
                            <v-card-text>
                                <v-row>
                                    <v-progress-circular class="ma-5 ahcRed" :size="70" :width="7"
                                        indeterminate></v-progress-circular>
                                </v-row>
                                <v-row>
                                    <h3>Verwijderen..</h3>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
</div>
</template>
<script>
    export default{
        name: 'VolunteerOverview',
        props: 
            {
                volunteers: {
                    type: Array,
                    required: true
                },
            },
        
            
        
        data(){
            return{
                volHeaders: [
                    { text: 'Naam', value: 'name' },
                    { text: 'Email', value: 'email' },
                    { text: 'Acties', value: 'actions', sortable: false }
                ],
                search: '',
                addVolDialog: false,
                editVolDialog: false,
                delVolDialog: false,
                newVol: {
                    name: ''
                },
                editVol: {
                },
                volToDelete: null,
                buzzy: false
            }
        },

        methods: {
            saveVol(){
                this.buzzy = true;
                this.$store.dispatch('volunteerStore/addVolunteer', {volunteer: this.newVol}).then(() => {
                    this.buzzy = false;
                    this.addVolDialog = false;
                    this.newVol = {};
                });
            },
            updateVol(){
                this.buzzy = true;
                this.$store.dispatch('volunteerStore/updateVolunteer', {volunteer: this.editVol}).then(() => {
                    this.buzzy = false;
                    this.editVolDialog = false;
                    this.editVol = {};
                });
            },
            deleteVol(){
                this.buzzy = true;
                this.$store.dispatch('volunteerStore/deleteVolunteer', {volunteerId: this.volToDelete}).then(() => {
                    this.buzzy = false;
                    this.delVolDialog = false;
                    this.volToDelete = null;
                });
            }
        },
        computed: {
            noVolAvailable(){
                return !this.volunteers || this.volunteers.length === 0;
            }
        }
    }
</script>