import Vue from 'vue'
import VueRouter from 'vue-router'
import AddUser from '@/components/auth/AddUser'
import EditUser from '@/components/auth/EditUser'
import Login from '../components/auth/Login'
import Users from '@/components/auth/Users'
import firebase from '@/firebase/init'
import store from '../store/index'
import HomepageBack from '@/components/website/HomepageBack'
import UbpfBack from '@/components/website/UbpfBack'
import CarnavalskrantBack from '@/components/website/CarnavalskrantBack'
import LiedjesfestivalBack from '@/components/website/LiedjesfestivalBack'
import ContactBack from '@/components/website/ContactBack'
import NewsItems from '@/components/website/NewsItems'
import Profile from '@/components/Profile'
import Members from '@/components/secretariaat/Members'
import Docs from '@/components/secretariaat/Docs'
import Commissions from '@/components/secretariaat/Commissions'
import Calendar from '@/components/secretariaat/Calendar'
import Workgroup from '@/components/Workgroup'
import Financial from '@/components/secretariaat/Financial'
import Club from '@/components/Club'
import SettingsOverview from '@/components/webshop/SettingsOverview'
import ProductOverview from '@/components/webshop/ProductOverview'
import AddNewProduct from '@/components/webshop/AddNewProduct'
import KindercarnavalBack from '@/components/website/KindercarnavalBack'
import AboutUsBack from '@/components/website/AboutUsBack'
import Zomercarnaval from '@/components/website/Zomercarnaval'
import KrantAdministratie from '@/components/Krant/KrantAdministratie'
import KrantMijnAdvertenties from '@/components/Krant/KrantMijnAdvertenties'
import JeugdraadBack from '@/components/website/Jeugdraad.vue'
import Volunteers from '@/components/volunteers/Volunteers'
import NoAccess from '../components/auth/NoAccess.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/geen_toegang',
    name: 'NoAccess',
    component: NoAccess,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/gebruikertoevoegen',
    name: 'AddUser',
    component: AddUser,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'secGebruikers'

    }
  },
  {
    path: '/gebruikers',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'secGebruikers'
    }

  },
  {
    path: '/gebruikeraanpassen/:uid',
    name: 'EditUser',
    component: EditUser,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'secGebruikers'
    }
  },
  {
    path: '/contact',
    name: 'ContactBack',
    component: ContactBack,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webContact'
    }
  },
  {
    path: '/homepage',
    name: 'HomepageBack',
    component: HomepageBack,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webHome'
    }
  },
  {
    path: '/ubpf',
    name: 'UbpfBack',
    component: UbpfBack,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webUBPF'
    }
  },
  {
    path: '/overons',
    name: 'AboutUsBack',
    component: AboutUsBack,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webHistory'
    }
  },
  {
    path: '/jeugdraad',
    name: 'JeugdraadBack',
    component: JeugdraadBack,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webJeugdraad'
    }
  },

  {
    path: '/liedjesfestival',
    name: 'LiedjesfestivalBack',
    component: LiedjesfestivalBack,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webLiedjes'
    }
  },
 
 
  {
    path: '/kindercarnaval',
    name: 'KindercarnavalBack',
    component: KindercarnavalBack,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webkinder'
    }
  },
  {
    path: '/carnavalskrant',
    name: 'CarnavalskrantBack',
    component: CarnavalskrantBack,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webKrant'

    }
  },
  {
    path: '/',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/nieuwsberichten',
    name: 'NewsItems',
    component: NewsItems,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webNews'

    }
  },
  {
    path: '/optocht',
    name: 'Optocht',
    component: Zomercarnaval,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'webOptocht'

    }
  },
  {
    path: '/ledenlijst',
    name: 'Members',
    component: Members,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'secComlist'

    }
  },
  {
    path: '/notulenagendas',
    name: 'Docs',
    component: Docs,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'secNotAge'

    }
  },


  {
    path: '/commissielijst',
    name: 'Commissions',
    component: Commissions,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'secComlist'

    }
  },
  {
    path: '/jaarprogramma',
    name: 'Calendar',
    component: Calendar,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'secjaarProg'

    }
  },
  {
    path: '/financien',
    name: 'Financiën',
    component: Financial,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'secFin'

    }
  },

  {
    path: '/commissies/:id',
    name: 'Workgroup',
    component: Workgroup,
    meta: {
      requiresAuth: true,
      requiresCommissionPermission: true,
    }
  },
  {
    path: '/club',
    name: 'Club',
    component: Club,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'clubAdmin'
    }
  },
 
  {
    path: '/voorraad/producten',
    name: 'ProductOverview',
    component: ProductOverview,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'stockAdmin'
    }
  },
  {
    path: '/voorraad/nieuw_product',
    name: 'AddNewProduct',
    component: AddNewProduct,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'stockAdmin'
    }
  },
  {
    path: '/voorraad/instellingen',
    name: 'SettingsOverview',
    component: SettingsOverview,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'stockAdmin'
    }
  },

  {
    path: '/krant_administratie',
    name: 'KrantAdministratie',
    component: KrantAdministratie,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'krantRed'
    }
  },
  {
    path: '/mijn_adverteerders',
    name: 'KrantMijnAdvertenties',
    component: KrantMijnAdvertenties,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'krantAdv'
    }
  },
  {
    path: '/boerenploeg',
    name: 'Volunteers',
    component: Volunteers,
    meta: {
      requiresAuth: true,
      requiresGeneralPermission: true,
      permissionId: 'vol'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async function checkCurrentUser(id) {
    if (store.state.currentUser == null) {
      try {
        await store.dispatch("getCurrentUser", id);
        await delay(2000); 
      } catch (error) {
        console.log('Error loading current user:', error);
        //firebase.auth().signOut();
      }
    }
  }
  // If route required authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Load user
    firebase.auth().onAuthStateChanged(async user => {
      // If user obj does not exist --> redirect to login page
      if (!user) {
        next({ name: 'Login' });
      } else {
        await checkCurrentUser(user.uid);        
        if (to.meta.requiresGeneralPermission) {
        
          let userPermissions = store.state.currentUser?.permissions?.general;
          if (userPermissions?.includes(to.meta.permissionId)) {
            next();
          } else {
            next({ name: 'NoAccess' });
          }
        } else if (to.meta.requiresCommissionPermission){
         
          let userPermissions = store.state.currentUser?.permissions?.commissions;
          if (userPermissions?.includes(to.params.id)) {
            next();
          } else {
            next({ name: 'NoAccess' });
          }
        } else {
          next();
        }
      }
    });
  } else {

    next();

  }
})

export default router
